












































































div[class*="rwg-app-"]{
  .rwg-title{
    text-align:center;
    margin-bottom:30px;
    font-size: 2em;
    font-weight: bold;
  }
  .rwg-review-header{
    background: #F3F3F3;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .rwg-header-business{
      font-size: 25px;
    }
    .rwg-review-header-left{
      flex:1;
    }
    .rwg-posted-on-source{
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 6px;
      span{
        margin-left: 10px;
        font-size: 20px;
      }
    }
    .rwg-review-header-bottom{
      display: flex;
      align-items: center;
      .rwg-review-avg-header{
        font-weight: bold;
        font-size: 20px;
        margin-right: 10px;
      }
      .rwg-review-rating{
        margin-top: -12px;
        margin-right: 10px;
      }
      .rwg-review-total{
        font-size: 12px;
      }
    }
    .rwg-write-review{
      a, button {
        text-decoration: none;
        padding: 12px 18px;
        border-radius: 10px;
        color: #fff;
        background: #4085f2;
        border: 0;
        appearance: none;
        cursor: pointer;
      }
    }
  }
  .rwg-caption{
    text-align: center;
    margin-bottom: 25px;
  }
  .rwg-review-rating{
    display: inline-block;
    unicode-bidi: bidi-override;
    color: #888888;
    font-size: 23px !important;
    span{
      font-size: 23px !important;
    }
    height: 25px;
    width: auto;
    margin: 0;
    position: relative;
    padding: 0;
    .rating-upper {
      color: #fccd00;
      padding: 0;
      position: absolute;
      z-index: 1;
      display: flex;
      top: 0;
      left: 0;
      overflow: hidden;
    }
    .rating-lower {
      padding: 0;
      display: flex;
      z-index: 0;
    }
  }
  #rwg-floating-rotator{
    padding: 15px;
    border: 1px solid #bebebe;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
    opacity: 1;
    transform: translate(0px,0%);
    transition: transform 300ms ease,opacity 300ms ease,-webkit-transform 300ms ease;
    max-width: 400px;
    width: 100%;
    display: flex;
    align-items:center;
    position:fixed;
    top:100%;
    #rwg-floating-link{text-decoration: none}
    &.visible{
      opacity: 1;
      transform: translate(0px,-100%);
      top:calc(100% - 15px);
      z-index: 10000;
    }
    .rwg-review-img{
      align-self: center;
      margin-right: 15px;
    }
    .rwg-floating-main{
      position: relative;
      flex: 1;
      flex-wrap: nowrap;
      .rwg-review-name{
        font-weight: bold;
        text-transform: capitalize;
      }
      .rwg-review-text{
        p{
          margin: 2px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    .rwg-floating-main-footer{
      display: flex;
      align-items: center;
      .rwg-posted-on-source{
        margin-top: 0;
        padding-right: 10px;
        svg{
          vertical-align: middle;
        }
      }
      .rwg-review-rating{
        margin-right:10px;
        font-size: 18px !important;
        span{
          font-size: 18px !important;
        }
      }
      .rwg-review-date{
        opacity: .8;
      }
    }
    .rwg-floating-close{
      position: absolute;
      right: -2px;
      top: 2px;
      appearance: none;
      background: none;
      border: 0;
      cursor: pointer;
    }
  }
  .rwg-review-text{
    p{
      line-height: 1.4;
    }
    input {
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }
    p.trunc-enabled {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin: 0;
    }
    input:checked + p.trunc-enabled {
      -webkit-line-clamp: unset;
    }
    p.trunc-enabled:not(.truncated) ~ label{
      display: none;
    }
    input:checked + p.trunc-enabled + label {
      display: block;
    }
    input:checked + p.trunc-enabled ~ label::after{
      content:'Read Less'
    }
    p.truncated.trunc-enabled ~ label::after{
      content:'Read More'
    }
    label.rwg-text-label{
      width: 100%;
      display: block;
      margin-top: 3px;
      font-size: 12px;
      opacity: .7;
    }
    label.rwg-text-label:hover{
      text-decoration: underline;
    }
  }
  .rwg-posted-on-source{
    margin-top: 10px;
    text-align: left;
    .rwg-posted-on{
      opacity: 0.7;
      font-size: 12px;
      margin-bottom: 3px;
    }
  }
  .rwg-review-link, a.grid-item, a.swiper-slide{
    text-decoration: none;
    color: inherit;
  }
  .rwg-grid{
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    justify-content: stretch;
    gap: 20px;
  }
  .rwg-list{
    display: grid;
    justify-content: stretch;
    gap: 20px;
  }
  .rwg-review-link{

  }
  .rwg-rev-comment{
    display: flex;
    flex-direction: column;
    text-align: left;
    .rwg-comment-top{
      background: #F3F3F3;
      position: relative;
      padding: 15px 30px;
      flex: 1;
      &::after {
        content: "";
        position: absolute;
        height: 20px;
        width: 25px;
        bottom: -20px;
        left: 10%;
        background-color: inherit;
        clip-path: polygon(0 0, 100% 0, 0 100%);
      }
    }
    .rwg-comment-bottom{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-height: 50px;
      > div{
        display: flex;
        flex-wrap: nowrap;
        padding-top: 25px;
        padding-left: 5%;
        align-items: center;
        .rwg-comment-bottom-right{
          text-align: left;
          padding-left: 15px;
          .rwg-review-name{
            font-weight: bold;
          }
        }
      }
    }
  }
  .rwg-review-img{
    height: 50px;
    width: 50px;
  }
  .rwg-review-name{
    font-size: 14px;
  }
  .rwg-review-date{
    font-size: 12px;
    opacity: 0.7;
  }
  .rwg-review-text{
    p{
      margin-top: 10px !important;
    }
  }
  .rwg-rev-centered{
    background: #F3F3F3;
    padding: 20px;
    text-align: center;
    .rwg-review-img{
      margin-bottom: 5px;
    }
    .rwg-review-name{
      font-weight: bold;
      margin-bottom: 5px;
    }
    .rwg-review-rating{
      margin-bottom: 10px;
      margin-top: -5px;
    }
    .rwg-review-date{
      margin-bottom: 5px;
    }
    .rwg-review-text{
      margin-top: 5px;
    }
    .rwg-posted-on-source{
      text-align: center;
    }
  }
  .rwg-rev-compact{
    background: #F3F3F3;
    padding: 20px;
    .rwg-review-link{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .rwg-compact-top{
      flex-wrap: nowrap;
      align-items: center;
      display: flex;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      > div{
        .rwg-review-img{
          margin-right:15px;
        }
        .rwg-review-name{
          text-align: left;
          font-weight: bold;
          margin-bottom: -1px;
        }
        .rwg-compact-lower-right{
          flex-wrap: wrap;
          align-items: flex-end;
          display: flex;
          .rwg-review-rating{
            font-size: 21px !important;
            span{
              font-size: 21px !important;
            }
            height: 23px;
            padding-right: 8px;
            margin-bottom:8px;
          }
          .rwg-review-date{
            margin-bottom:6px;
          }
        }
      }
    }
    .rwg-review-text{
      padding-top: 10px;
      text-align: left;
    }
  }
  .rwg-masonry-grid{
    position: relative;
    /* clearfix */
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    .grid-item{
      box-sizing: border-box;
    }
  }
  /*Force Responsive*/
  @media screen and (max-width:980px){
    .rwg-masonry-grid .grid-item{
      width: 33.33% !important;
    }
    .rwg-grid[style*="repeat(4,"]{
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }
  @media screen and (max-width:768px){
    .rwg-masonry-grid .grid-item{
      width: 50% !important;
    }
    .rwg-grid[style*="repeat(4,"],.rwg-grid[style*="repeat(3,"]{
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
  @media screen and (max-width:640px){
    .rwg-grid{
      grid-template-columns: repeat(1, 1fr) !important;
      &[style*="repeat(4,"],&[style*="repeat(3,"]{
        grid-template-columns: repeat(1, 1fr) !important;
      }
    }
  }
  @media screen and (max-width:550px){
    .rwg-masonry-grid .grid-item{
      width: 100% !important;
    }
    .rwg-review-header{
      display: block;
      .rwg-review-header-left{
        flex: none;
      }
      .rwg-posted-on-source,
      .rwg-review-header-bottom{
        align-items: center;
        margin-bottom: 10px;
        .rating-upper{
          span{
            font-size: 23px !important;
          }
        }
      }
      .rwg-write-review{
        a{
          margin-top: 20px;
          display: block;
        }
      }
    }
    #rwg-floating-rotator{
      box-sizing: border-box;
      width: calc(100% - 20px);
    }
    &{
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  /**************************\
Basic Modal Styles
\**************************/

  .modal {
    font-family: -apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif;
  }

  .modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
  }

  .modal__container {
    background-color: #fff;
    padding: 30px 30px 0 30px;
    max-width: 500px;
    max-height: 100vh;
    border-radius: 0;
    overflow-y: auto;
    box-sizing: border-box;
    .gate-header{
      font-size: 26px;
      margin-top: 0;
    }
    .gate-buttons {
      display: flex;
      margin-top: 40px;
      > * {
        flex: 1;
        text-align: center;
      }
      .positive {
        color: #599b59;
      }
      .negative {
        color: #e75f5f;
        border: 0;
        appearance: none;
        background: none;
        cursor: pointer;
      }
    }
    .negative-form{
      form {
        label {
          display: block;
          margin-top: 8px;
          margin-bottom: 3px;
        }

        input, textarea {
          padding: 3px 10px;
          width: 100%;
          border-radius: 8px;
          min-height: 35px;
          border: 1px solid #ccc;
          box-sizing: border-box;
        }

        textarea {
          padding: 10px;
        }

        button {
          color: #fff;
          appearance: none;
          cursor: pointer;
          background: #4085f2;
          border: 0;
          border-radius: 10px;
          padding: 12px 18px;
          text-decoration: none;
          width: 100%;
          margin-top: 10px;
        }

        .gate-errors {
          margin-top: 0;
          padding-left: 20px;
          color: red;
        }

        .success-message {
          text-align: center;
          margin-top: 10px;
          color: green;
        }

        button.gate-submit {
          position: relative;
          &.is-working {
            span {
              visibility: hidden;
              opacity: 0;
            }

            &::after {
              content: "";
              position: absolute;
              width: 16px;
              height: 16px;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              border: 4px solid transparent;
              border-top-color: #ffffff;
              border-radius: 50%;
              animation: button-loading-spinner 1s ease infinite;
            }
          }
        }

        @keyframes button-loading-spinner {
          from {
            transform: rotate(0turn);
          }

          to {
            transform: rotate(1turn);
          }

        }
      }
    }
    .skip-gate{
      color: #7b7b7b;
      background: #d9d9d8;
      margin-left: -30px;
      margin-right: -30px;
      text-align: center;
      padding: 5px 10px;
      margin-top: 30px;
      font-size: 14px;
      border-bottom: 2px solid #8a8a8b;
      a{
        color: #538ef4;
      }
    }
  }

  .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25;
    color: #00449e;
    box-sizing: border-box;
  }

  .modal__close {
    background: transparent;
    border: 0;
  }

  .modal__header .modal__close:before { content: "\2715"; }

  .modal__content {
    line-height: 1.5;
    color: rgba(0,0,0,.8);
  }

  .modal__btn {
    font-size: .875rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background-color: #e6e6e6;
    color: rgba(0,0,0,.8);
    border-radius: .25rem;
    border-style: none;
    border-width: 0;
    cursor: pointer;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
    line-height: 1.15;
    margin: 0;
    will-change: transform;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    transition: -webkit-transform .25s ease-out;
    transition: transform .25s ease-out;
    transition: transform .25s ease-out,-webkit-transform .25s ease-out;
  }

  .modal__btn:focus, .modal__btn:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  .modal__btn-primary {
    background-color: #00449e;
    color: #fff;
  }



  /**************************\
    Demo Animation Style
  \**************************/
  @keyframes mmfadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes mmfadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }

  @keyframes mmslideIn {
    from { transform: translateY(15%); }
    to { transform: translateY(0); }
  }

  @keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
  }

  .micromodal-slide {
    display: none;
  }

  .micromodal-slide.is-open {
    display: block;
  }

  .micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide .modal__container,
  .micromodal-slide .modal__overlay {
    will-change: transform;
  }
}

